import React, { useEffect } from 'react';
import * as styles from './Layout.module.scss';
import '../../basestyles/styles.scss';
import TopBar from '../TopBar';
import { Link } from 'gatsby';
import Logo from '../Logo';
import CookieConsent from 'react-cookie-consent';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

interface ILayoutProps {
    children: React.ReactChild;
}

const Layout = ({children}: ILayoutProps) => {
    const location = useLocation();
    useEffect(() => {
        if (typeof googletag !== "undefined") {
            googletag.cmd.push(function() { googletag.pubads().refresh(); });
        }
    },[location])
    return (
        <div className={styles.mainLayout}>
            <TopBar />
            <div className={styles.logo}>
                <Logo />
            </div>
            {children}
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="cookieConsent"
                style={{ background: "#fbfffe", color: "#211c33", fontFamily: "Gotham-Black", fontSize: "14px"}}
                buttonStyle={{ backgroundColor: "#fff05a", color: "#211c33", fontSize: "14px", textTransform: "uppercase" }}
                expires={270}
                >
                This website uses <Link to="/cookies/">cookies</Link> for advertising, analytics, and to enhance the user experience.
                </CookieConsent>
        </div>
    )
}

export const Container = ({ children }: ILayoutProps) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

export const FullWidthContainer = ({ children }: ILayoutProps) => {
    return (
        <div className={styles.fullWidthContainer}>
            {children}
        </div>
    )
}


export default Layout;
