import React from 'react';
import * as styles from './Header.module.scss';

type HeaderProps = {
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
    text: String,
    light?: boolean,
}

const Header = ({ type, text, light = false }: HeaderProps) => {
    const cls = light ? styles.light : styles.dark;
    return React.createElement(type, { className: `${styles.header} ${cls}` }, text);
}

export default Header;
