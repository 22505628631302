import React, { useEffect } from 'react';
import * as styles from './TopBar.module.scss';
import NavMenu from '../NavMenu';
import HomeBtn from '../HomeBtn';
import GoogleAd from '../GoogleAd';

const TopBar = () => {
    return (
        <>
            <div className={styles.ad} style={{ maxHeight: "90px"}}>
                <GoogleAd divId="div-gpt-ad-1620602205892-0" />
            </div>
            <div className={styles.topBar}>
                <div className={styles.header}>
                    <NavMenu />
                    <HomeBtn />
                </div>
            </div>
        </>
    )
}

export default TopBar;
