export const randNumber = (max: number) => {
    return Math.floor(Math.random() * max);
}

export const randNumberRange = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

export const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const cryptoShuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const generateSecurePassword = (additionalLength: number = 8) => {
    const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lower = upper.toLowerCase();
    const numbers = '0123456789';
    const special = ' !"#$%&\'()*+,-./:;<=>?@[]\\^_`{|}~';
    const combined = `${upper}${lower}${numbers}${special}`;

    let arr = [];

    arr.push(upper[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * upper.length)]);

    arr.push(lower[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * lower.length)]);

    arr.push(numbers[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * numbers.length)]);

    arr.push(special[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * special.length)]);

    arr.push(Array(additionalLength)
      .fill('')
      .map(() => combined[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * combined.length)]));

    return cryptoShuffleArray(arr.flat()).join('');
}