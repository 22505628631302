import React from 'react';
import { Helmet } from 'react-helmet';
import metaImage from './og-home-page.png';

interface MetaProps {
    title: string,
    description: string,
    path?: string,
    image?: string,
    noIndex?: Boolean,
}

const Meta = ({ title, description, path = '', image = metaImage, noIndex = false }: MetaProps) => {
    const fullTitle = `${title} | Generator Ninja`;
    const fullUrl = `https://generator.ninja${path}`;
    return (
        <>
            <Helmet htmlAttributes={{ lang: "en"}}>
                <title>{fullTitle}</title>
                {noIndex ? <meta name="robots" content="noindex" /> : ''}
                <meta name="description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={fullTitle} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={fullUrl} />
                <meta property="twitter:card" content="summary" />
                <meta property="twitter:title" content={fullTitle} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content={image} />
                <meta property="twitter:url" content={fullUrl} />
            </Helmet>
        </>
    )
}

export default Meta;
