import React from 'react';
import { Link } from 'gatsby';
import logoSVG from './logo-v3.svg';

const Logo = () => {
    return (
        <Link to="/">
            <img width="400" height="151" src={logoSVG} alt="Generator Ninja Logo" />
        </Link>
    )
}

export default Logo;
