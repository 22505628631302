import React from 'react';
import * as styles from './HomeBtn.module.scss';
import Icon from '../Icon';
import { homeIcon } from '../../utilities/icons';
import { Link } from 'gatsby';

interface IHomeBtnProps {
    showText?: Boolean,
    light?: Boolean,
}

const HomeBtn = ({ showText = false, light = false }: IHomeBtnProps) => {
    return (
        <div className={`${styles.homeBtn} ${light ? styles.light : styles.dark}`}>
            <Link to="/"><Icon icon={homeIcon} style={light ? styles.light : styles.dark} /><span className={`${styles.homeBtnText} ${showText? '' : styles.hidden}`}>Home</span></Link>
        </div>
    )
}

export default HomeBtn;
