import React from 'react';

interface IconProps {
    style?: string,
    icon: string,
}

const Icon = ({ icon, style = '' }: IconProps) => {
    const ic = {__html: icon.toString()};
    return (
        <span dangerouslySetInnerHTML={ic} className={style} />
    )
}

export default Icon;
