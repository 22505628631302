import React from 'react'

interface Props {
    divId: string,
    width?: string,
    height?: string,
}

const GoogleAd = ({ divId, width = "auto", height = "auto"}: Props) => {
    return (
        <div key={`div_${divId}`} id={divId} style={{ width: `${width}`, height: `${height}`}}>
            <script dangerouslySetInnerHTML={{ __html: `googletag.cmd.push(function() { googletag.display('${divId}'); });`}}></script>
        </div>
    )
}

export default GoogleAd;
