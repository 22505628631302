import React, { useState } from 'react';
import * as styles from './NavMenu.module.scss';
import { Link, useStaticQuery, graphql } from 'gatsby';
import HomeBtn from '../HomeBtn';
import Icon from '../Icon';
import { arrowDownIcon, arrowUpIcon, hamburgerMenuIcon, healthIcon } from '../../utilities/icons';

const NavMenu = () => {
    const [open, setOpen] = useState(false);
    const data = useStaticQuery(graphql`
        query ActiveCategoriesAndPages {
            allMongodbGeneratorninjaCategories(filter: {active: {eq: true}}) {
            edges {
                node {
                id
                name
                slug
                icon
                }
            }
            }
            allMongodbGeneratorninjaFixedattrs(filter: {active: {eq: true}}) {
            nodes {
                name
                slug
                category
            }
            }
            allMongodbGeneratorninjaNocards(filter: {active: {eq: true}}) {
            nodes {
                name
                slug
                category
            }
            }
            allMongodbGeneratorninjaRandomattrs(filter: {active: {eq: true}}) {
            nodes {
                category
                name
                slug
            }
            }
        }
    `);
    const categories = data.allMongodbGeneratorninjaCategories.edges;
    let pages = [];
    data.allMongodbGeneratorninjaFixedattrs.nodes.forEach((node) => {
        pages.push(node);
    });
    data.allMongodbGeneratorninjaRandomattrs.nodes.forEach((node) => {
        pages.push(node);
    });
    data.allMongodbGeneratorninjaNocards.nodes.forEach((node) => {
        pages.push(node);
    });
    return (
        <div className={styles.navMenu}>
            <div onClick={() => setOpen(true)}>
                <Icon icon={hamburgerMenuIcon} />
            </div>
            <div className={`${styles.navMenuDrawer} ${open ? '' : styles.hidden}`}>
                <div onClick={() => setOpen(false)} className={styles.closeBtn}>
                    <Icon icon={healthIcon} />
                </div>
                <div className={styles.navMenuItems}>
                    <ul>
                        <li className={styles.category} onClick={() => setOpen(false)}><HomeBtn light={true} showText={true} /></li>
                        {categories.map((category) =>
                            <CategoryWithPages key={category.node.id} name={category.node.name} icon={category.node.icon}>
                                <>
                                    {pages.map((page) => {
                                        if(category.node.name === page.category) return <li key={page.name}><Link to={page.slug}>{page.name}</Link></li>
                                    })}
                                </>
                            </CategoryWithPages>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

interface ICategoryWithPages {
    children: React.ReactChild,
    name: string,
    icon: string,
}

const CategoryWithPages = ({ name, icon, children }: ICategoryWithPages) => {
    const [open, setOpen] = useState(false);
    return (
        <li onClick={() => setOpen(!open)} className={styles.category}>
            <span className={styles.categoryName}><Icon icon={icon} style={styles.category} />{name}<span className={styles.categoryToggle}>{open ? <Icon icon={arrowUpIcon} /> : <Icon icon={arrowDownIcon} />}</span></span>
            <ul className={`${styles.categoryPages} ${!open ? styles.hidden : ''}`}>
                {children}
            </ul>
        </li>
    )
}

export default NavMenu;
