import React, { useEffect, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import * as styles from './Footer.module.scss';
import Header from '../Header';
import Icon from '../Icon';
import { generateIcon } from '../../utilities/icons';
import { shuffleArray } from '../../utilities/random';
import HomeBtn from '../HomeBtn';
import Logo from '../Logo';

const Footer = () => {
    const data = useStaticQuery(graphql`
        query ActivePagesForFooter {
            allMongodbGeneratorninjaFixedattrs(filter: {active: {eq: true}}) {
            nodes {
                name
                category
                slug
            }
            }
            allMongodbGeneratorninjaNocards(filter: {active: {eq: true}}) {
            nodes {
                name
                category
                slug
            }
            }
            allMongodbGeneratorninjaRandomattrs(filter: {active: {eq: true}}) {
            nodes {
                name
                category
                slug
            }
            }
        }
    `);
    const [ randomPages, setRandomPages ] = useState([]);

    let pages = [];
    data.allMongodbGeneratorninjaFixedattrs.nodes.forEach((node) => {
        pages.push(node);
    });
    data.allMongodbGeneratorninjaRandomattrs.nodes.forEach((node) => {
        pages.push(node);
    });
    data.allMongodbGeneratorninjaNocards.nodes.forEach((node) => {
        pages.push(node);
    });

    const randomizePages = () => {
        const newPages = shuffleArray(pages).slice(0,40);
        setRandomPages(newPages);
    }
    // eslint-disable-next-line
    useEffect(() => randomizePages(), []);
    return (
        <footer className={styles.footer}>
            <div className={styles.footerTop}>
                <Logo />
                <HomeBtn light={true} />
            </div>
            <div>
                <div onClick={() => randomizePages()} className={styles.generateBtn}>
                    <div className={styles.generateBtnHeader}>
                        <Header type="h4" text="Random Generators" light={true} /> <Icon icon={generateIcon} style={styles.generateIcon} />
                    </div>
                    <span className={styles.smallText}>(psst. tap me to generator more)</span>
                </div>
                <div className={styles.links}>
                    {randomPages.map((page) =>
                        <Link key={page.name} className={styles.footerLink} to={page.slug}>{page.name}</Link>
                    )}
                </div>
            </div>
            <span className={styles.copyright}>© 2019 - {new Date().getFullYear().toString()} Generator Ninja | <Link to="/privacy/">Privacy Policy</Link> <Link to="/tos/">Terms of Service</Link> <Link to="/cookies/">Cookie Policy</Link><a href="https://www.foreversnipersvsrunners.com">Forever Snipers vs Runners 🏃</a></span>
        </footer>
    )
}

export default Footer;
