// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--3mBiF";
export var footerTop = "Footer-module--footerTop--Aucpm";
export var homeIcon = "Footer-module--homeIcon--3XenL";
export var generateIcon = "Footer-module--generateIcon--1X8H5";
export var generateBtn = "Footer-module--generateBtn--sMr41";
export var generateBtnHeader = "Footer-module--generateBtnHeader--3ESo4";
export var smallText = "Footer-module--smallText--2cE-i";
export var footerLink = "Footer-module--footerLink--2Ue1k";
export var links = "Footer-module--links--1BKb1";
export var copyright = "Footer-module--copyright--2GL1M";